import { Snackbar } from '@ics-portal/react';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CheckIcon, ErrorIcon, InfoIcon } from '~/components/_atoms/icons';
import type { Navigation } from '../interfaces/IMenuData';

const SNACKBAR_ICONS = {
    info: <InfoIcon />,
    error: <ErrorIcon />,
    success: <CheckIcon />,
};

interface Snack {
    type: 'info' | 'error' | 'success';
    content: string;
}

interface Values {
    appClassName: 'collapsed' | 'expanded';
    setAppClassName: Dispatch<SetStateAction<'collapsed' | 'expanded'>>;
    appSidebarHidden: boolean;
    setAppSidebarHidden: Dispatch<SetStateAction<boolean>>;
    pushSnackbar: (snack: Snack) => void;
    // Secondary Menu
    secondaryMenuVisible: boolean;
    setSecondaryMenuVisible: Dispatch<SetStateAction<boolean>>;
    secondaryMenuContent: Navigation | null;
    setSecondaryMenuContent: Dispatch<SetStateAction<Navigation | null>>;
}

const GlobalUiContext = createContext<Values>({
    appClassName: 'expanded',
    setAppClassName: () => null,
    appSidebarHidden: false,
    setAppSidebarHidden: () => null,
    pushSnackbar: () => null,
    // Secondary Menu
    secondaryMenuVisible: false,
    setSecondaryMenuVisible: () => null,
    secondaryMenuContent: null,
    setSecondaryMenuContent: () => null,
});

export function GlobalUiProvider({ children }: PropsWithChildren) {
    const appNameStorage = window.localStorage.getItem('appClassName') as
        | 'collapsed'
        | 'expanded'
        | null;
    const [appClassName, setAppClassName] = useState<'collapsed' | 'expanded'>(
        appNameStorage ? appNameStorage : 'expanded',
    );
    useEffect(() => {
        window.localStorage.setItem('appClassName', appClassName);
    }, [appClassName]);

    const [appSidebarHidden, setAppSidebarHidden] = useState(false);
    const [activeSnack, setActiveSnack] = useState<Snack | null>(null);

    // Secondary Menu
    const [secondaryMenuVisible, setSecondaryMenuVisible] = useState(false);
    const [secondaryMenuContent, setSecondaryMenuContent] =
        useState<Navigation | null>(null);

    const debouncedSnackReset = () => {
        let timer: ReturnType<typeof setTimeout> | null = null;
        return () => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                setActiveSnack(null);
            }, 6000);
        };
    };

    const pushSnackbar = (snack: Snack) => {
        setActiveSnack(snack);
        debouncedSnackReset()();
    };

    const globalUiState = useMemo(
        () => ({
            appClassName,
            setAppClassName,
            pushSnackbar,
            appSidebarHidden,
            setAppSidebarHidden,
            secondaryMenuVisible,
            setSecondaryMenuVisible,
            secondaryMenuContent,
            setSecondaryMenuContent,
        }),
        [
            appClassName,
            appSidebarHidden,
            secondaryMenuVisible,
            setSecondaryMenuVisible,
            secondaryMenuContent,
            setSecondaryMenuContent,
        ],
    );

    return (
        <GlobalUiContext.Provider value={globalUiState}>
            {Boolean(activeSnack) && (
                <Snackbar
                    {...activeSnack}
                    icon={SNACKBAR_ICONS[activeSnack.type]}
                />
            )}
            {children}
        </GlobalUiContext.Provider>
    );
}

export function useGlobalUI() {
    const context = useContext(GlobalUiContext);

    if (!context) {
        throw new Error('useGlobalUI must be used within a GlobalUiProvider');
    }

    return context;
}

export function useSnackbar() {
    const { pushSnackbar } = useContext(GlobalUiContext);
    return pushSnackbar;
}
