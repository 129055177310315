import type { PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';

type AvatarSize = 'normal' | 'large';

export interface AvatarProps {
    /**
     * Size of the avatar
     */
    size?: AvatarSize;
    /**
     * Optional class name
     */
    className?: string;
}

/**
 * Display an avatar with the initials of the user
 */
export default function Avatar({
    children,
    size = 'normal',
    className,
    ...rest
}: PropsWithChildren<AvatarProps>): ReactNode {
    const classes = clsx(
        'ics-avatar',
        {
            [`ics-avatar--${size}`]: size,
        },
        className,
    );

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
}
