import { createContext, useContext } from 'react';

interface DrawerContextValue {
    modal: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export const DrawerContext = createContext<DrawerContextValue | null>({
    modal: true,
});

export function useDrawerContext(): DrawerContextValue {
    const context = useContext(DrawerContext);
    if (!context) {
        throw new Error('useDrawerContext must be used within a Drawer.Root');
    }
    return context;
}
