import type { ElementType, HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { clsx } from 'clsx';

/**
 * The steps in the spacing scale
 */
type SPACING_SIZE = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export interface StackProps extends HTMLAttributes<HTMLElement> {
    /**
     * Provide a custom element type to render as the outermost element in
     * the Stack component. By default, this component will render a `div`.
     */
    as?: (() => ReactNode) | string | ElementType;

    /**
     * Provide the elements that will be rendered as children inside the Stack
     * component. These elements will have had spacing between them according
     * to the `step` and `orientation` prop
     */
    children?: ReactNode;

    /**
     * Provide a custom class name to be used by the outermost element rendered by
     * Stack
     */
    className?: string;

    /**
     * Provide a step from the spacing scale to be used as the gap in the layout
     *
     */
    gap?: SPACING_SIZE | 'none';

    /**
     * Specify the orientation of them items in the Stack
     */
    orientation?: 'horizontal' | 'vertical';
}

/**
 * The Stack component is a useful layout utility in a component-based model.
 * This allows components to not use margin and instead delegate the
 * responsibility of positioning and layout to parent components.
 *
 * In the case of the Stack component, it uses our spacing scale to
 * determine how much space there should be between
 * items rendered by the Stack component.
 *
 * This component supports both horizontal and vertical orientations.
 *
 */
export const Stack = forwardRef<ReactNode, StackProps>(
    function Stack(props, ref) {
        const {
            as: BaseComponent = 'div',
            children,
            className: customClassName,
            gap = 'none',
            orientation = 'vertical',
            ...rest
        } = props;
        const className = clsx(
            'ics-stack',
            {
                [`ics-stack--${orientation}`]: true,
                [`ics-stack--scale-${gap}`]: gap !== 'none',
            },
            customClassName,
        );

        return (
            <BaseComponent {...rest} ref={ref} className={className}>
                {children}
            </BaseComponent>
        );
    },
);
