import { ProjectStatus } from '../../../utils/project';

export function getProjectStatus(name?: string): ProjectStatus {
    if (!name) {
        return ProjectStatus.Open;
    }
    switch (name) {
        case 'closed':
            return ProjectStatus.Closed;
        case 'in progress':
            return ProjectStatus.InProgress;
        case 'on hold':
            return ProjectStatus.OnHold;
    }
    return ProjectStatus.Open;
}
