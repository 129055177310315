import { type ReactNode } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { divIcon, type LeafletMouseEvent, type Layer } from 'leaflet';
import { Flex } from '@ics-portal/react/src/index';
import { type Project } from '../../../gql/graphql';
import {
    getMarkerIconColor,
    getMarkerIconSvgString,
} from '../../../components/_atoms/projectMarkerIcon';
import { getProjectIcon, ProjectStatus } from '../../../utils/project';
import { Line } from '../../../components/_atoms/Line/line';
import { useMapContext } from '../_utils/map-context';
import { getProjectStatus } from '../_utils/utils';
import s from './marker.module.css';

interface ProjectMarkerProps {
    project: Project;
}

export function ProjectMarker({ project }: ProjectMarkerProps): ReactNode {
    const { setProject } = useMapContext();
    const color = getMarkerIconColor(getProjectStatus(project.status?.name));
    const markerIcon = divIcon({
        iconAnchor: [12, 12],
        iconSize: [12, 12],
        html: getMarkerIconSvgString(color),
    });

    if (!project.point) {
        return null;
    }

    return (
        <Marker
            key={project.id}
            position={[project.point.latitude, project.point.longitude]}
            icon={markerIcon}
            eventHandlers={{
                mouseover: (event: LeafletMouseEvent) =>
                    (event.target as Layer).openPopup(),
                mouseout: (event: LeafletMouseEvent) =>
                    (event.target as Layer).closePopup(),
                click: () => {
                    setProject(project);
                },
            }}
        >
            <Popup
                interactive={false}
                className={s.projectPopup}
                closeButton={false}
                offset={[0, -6]}
            >
                <Flex direction="row" gap="md" jc="flex-start" ai="flex-start">
                    <Flex
                        direction="column"
                        jc="flex-start"
                        ai="flex-start"
                        gap="md"
                    >
                        <h2>{project.name} </h2>
                        <Flex
                            direction="column"
                            gap="sm"
                            jc="flex-start"
                            ai="flex-start"
                        >
                            <div>{project.customerName}</div>
                            {Boolean(project.organization?.name) && (
                                <div className={s.projectOrganizationName}>
                                    {project.organization?.name}
                                </div>
                            )}
                        </Flex>
                    </Flex>
                    <Flex>{getProjectIcon(project.type?.id)}</Flex>
                </Flex>
                <Line />
                <Flex jc="flex-end">
                    {project.status?.name} - {project.phase?.name}
                </Flex>
            </Popup>
        </Marker>
    );
}
