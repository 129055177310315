import type { PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';
import { ChevronLeftIcon } from '../_atoms/icons';
import s from './info-card.module.scss';
import { useInfoCard } from './use-info-card';

interface InfoCardProps extends PropsWithChildren {
    /**
     * Provide a custom className
     */
    className?: string;
    /**
     * Supply a different label for the toggle button, defaults to "Toggle info card"
     */
    toggleLabel?: string;
}

export function InfoCard({
    className,
    toggleLabel = 'Toggle info card',
    children,
}: InfoCardProps): ReactNode {
    const { open, toggle, toggleDisabled } = useInfoCard();
    const classes = clsx(
        s.container,
        {
            [s.open]: open,
        },
        className,
    );
    return (
        <div className={classes}>
            {!toggleDisabled && (
                <button
                    className={s.button}
                    onClick={toggle}
                    title={toggleLabel}
                    type="button"
                >
                    <ChevronLeftIcon />
                </button>
            )}
            <div id="info-card" className={s.inner}>
                <div className={s.scrollArea}>{children}</div>
            </div>
        </div>
    );
}
