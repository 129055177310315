import { type ReactNode } from 'react';
import s from './VisuallyHidden.module.css';

interface Props {
    children: ReactNode;
    id?: string;
}

function VisuallyHidden({ children, id }: Props) {
    if (id) {
        return (
            <div className={s.hidden} id={id}>
                {children}
            </div>
        );
    }
    return <div className={s.hidden}>{children}</div>;
}

export default VisuallyHidden;
