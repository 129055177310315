import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import type { AccordionType } from './accordion-types';

interface AccordionStateContext {
    open: string[];
    type: AccordionType;
}

interface AccordionDispatchContext {
    setOpen: (value: string[]) => void;
}

const AccordionStateContext = createContext<AccordionStateContext | null>(null);
const AccordionDispatchContext = createContext<AccordionDispatchContext | null>(
    null,
);

interface AccordionProviderProps {
    children: ReactNode;
    open: string[];
    setOpen: (value: string[]) => void;
    type: AccordionType;
}

export function AccordionProvider({
    children,
    open,
    setOpen,
    type,
}: AccordionProviderProps): ReactNode {
    return (
        <AccordionStateContext.Provider value={{ open, type }}>
            <AccordionDispatchContext.Provider value={{ setOpen }}>
                {children}
            </AccordionDispatchContext.Provider>
        </AccordionStateContext.Provider>
    );
}

export function useAccordion(): AccordionStateContext &
    AccordionDispatchContext {
    const value = useContext(AccordionStateContext);
    const dispatch = useContext(AccordionDispatchContext);
    if (!value || !dispatch) {
        throw new Error(
            'useAccordion must be used within an AccordionProvider',
        );
    }
    return { ...value, ...dispatch };
}
