import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import type { GetMeQuery, User } from '~/gql/graphql';
import { ME } from '~/api/queries/users';

export default function useUser(): User | undefined {
    const [user, setUser] = useState<User>();

    const { data } = useQuery<GetMeQuery>(ME);

    useEffect(() => {
        if (data) {
            setUser(data.me as User);
        }
    }, [data, setUser]);

    return user;
}
