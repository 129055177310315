import { forwardRef } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import { clsx } from 'clsx';

export interface ButtonProps {
    /**
     * Button text
     */
    label: string | ReactNode;
    /**
     * Is the button a variant styled button and which color scheme does it use?
     */
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'critical'
        | 'ghost'
        | 'primary-new'
        | 'secondary-new';
    /**
     * Optional click handler
     */
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    /**
     * Button icon
     */
    icon?: ReactNode;
    /**
     * Icon alignment
     */
    rightIcon?: ReactNode;
    /**
     * Hide label text visually and only show icon in the button
     */
    iconOnly?: boolean;
    /**
     * Set button as disabled
     */
    disabled?: boolean;
    /**
     * Set button as inverted
     */
    inverted?: boolean;
    /**
     * Additional (optional) className
     */
    className?: string;
    /**
     * Button type
     */
    type?: 'submit' | 'reset' | 'button';
    /**
     * Aria role
     */
    role?: string;
    /**
     * Specifies which form the button belongs to
     */
    form?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    function Button(props, forwardedRef) {
        const {
            variant = 'primary',
            label,
            icon,
            rightIcon,
            iconOnly = false,
            inverted = false,
            className,
            type = 'button',
            ...rest
        } = props;

        const classes = clsx(
            'ics-button-variant',
            {
                [`ics-button-variant--${variant}`]: true,
                'ics-button--icon-only': icon && iconOnly,
                'ics-button--inverted': inverted,
            },
            className,
        );

        const labelClasses = clsx('ics-button__text', {
            'ics-sr-only': icon && iconOnly,
        });

        return (
            <button
                className={classes}
                type={type}
                ref={forwardedRef}
                {...rest}
            >
                {Boolean(icon) && (
                    <span className="ics-button-variant__icon">{icon}</span>
                )}
                <span className={labelClasses}>{label}</span>
                {Boolean(rightIcon) && (
                    <span className="ics-button-variant__icon">
                        {rightIcon}
                    </span>
                )}
            </button>
        );
    },
);
