import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button, Flex } from '@ics-portal/react';
import type { MouseEvent } from 'react';
import { ForwardIcon } from '~/components/_atoms/icons';
import LargeLogo from '~/components/_atoms/logos/LargeLogo';
import { loginPopupRequest } from '../auth/msal/authConfig';
import s from './WelcomePage.module.css';

export default function WelcomePage() {
    const { instance, accounts, inProgress } = useMsal();

    const handleOnLogin = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (inProgress === InteractionStatus.None && accounts.length === 0) {
            void instance.loginPopup(loginPopupRequest);
        }
    };

    const isLoggingIn = inProgress === InteractionStatus.Login;

    return (
        <div className={s.container}>
            <Flex
                className={s.content}
                direction="column"
                gap="xl"
                jc="center"
                maxWidth="24.25rem"
            >
                <LargeLogo className={s.logo} />
                <p className="text-body text-white px-md text-center">
                    Welcome to Siemens Energy Industrial Cybersecurity Portal,
                    use your Siemens Energy credentials to log in.
                </p>
                <div className={s.divider} />
                <Button
                    className={s.button}
                    disabled={isLoggingIn}
                    label="Log in to ICS portal"
                    onClick={handleOnLogin}
                    rightIcon={<ForwardIcon />}
                    variant="secondary"
                />
            </Flex>
        </div>
    );
}
