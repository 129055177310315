import { type ReactNode } from 'react';
import { useMemo } from 'react';
import { ProjectStatus } from '~/utils/project';
import { ProjectMarkerIcon } from '~/components/_atoms/projectMarkerIcon';
import s from './legend.module.css';

interface ProjectStatusElementProps {
    status: ProjectStatus;
}

export function ProjectStatusElement({
    status,
}: ProjectStatusElementProps): ReactNode {
    const label = useMemo(() => {
        switch (status) {
            case ProjectStatus.InProgress:
                return 'In progress';
            case ProjectStatus.OnHold:
                return 'On hold';
            case ProjectStatus.Closed:
                return 'Closed';
            default:
                return 'Open';
        }
    }, [status]);
    return (
        <div className={s.legendElement}>
            <ProjectMarkerIcon status={status} />
            <span>{label}</span>
        </div>
    );
}
