import type { ReactNode, SelectHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { Label } from '../Label';
import { FormGroup } from '../FormGroup';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    /**
     * The id of the select component
     */
    id?: string;

    /**
     * Provide label text to be read by screen readers when interacting with the control.
     */
    label?: string | ReactNode;

    /**
     * Defines if current value which select is holding - is actually a placeholder. So proper class will be added to change it's color
     */
    isPlaceholder?: boolean;

    /**
     * Specify whether the label should be hidden, or not
     */
    hideLabel?: boolean;

    /**
     * Specify if the current value is invalid.
     */
    invalid?: boolean;

    /**
     * Message which is displayed if the value is invalid.
     * (use it together with the "invalid" property)
     */
    invalidText?: string;

    /**
     * Specify whether the control is disabled
     */
    disabled?: boolean;

    /**
     * The children of the select component. This should be a list of `<option>` elements.
     */
    children: ReactNode | ReactNode[];

    /**
     * The class name of the select component.
     */
    className?: string;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    function Select(props, forwardedRef) {
        const {
            id,
            label,
            hideLabel = false,
            invalid = false,
            invalidText,
            disabled = false,
            children,
            className,
            isPlaceholder,
            ...rest
        } = props;

        const labelClasses = clsx('ics-select__label', {
            'ics-visually-hidden': hideLabel,
            'ics-select__label--label--disabled': disabled,
        });

        const wrapperClasses = clsx('ics-select', className, {
            'ics-select--disabled': disabled,
            'ics-select--placeholder': isPlaceholder,
        });

        const inputClasses = clsx('ics-select__input');

        return (
            <FormGroup>
                {!hideLabel && (
                    <Label className={labelClasses} htmlFor={id}>
                        {label}
                    </Label>
                )}
                <div className={wrapperClasses}>
                    <select
                        className={inputClasses}
                        disabled={disabled}
                        id={id}
                        ref={forwardedRef}
                        {...rest}
                    >
                        {children}
                    </select>
                </div>
                {invalid && invalidText ? (
                    <div className="ics-form-input--error">{invalidText}</div>
                ) : null}
            </FormGroup>
        );
    },
);
