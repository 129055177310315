import { ApolloLink } from '@apollo/client';

/**
 * Apollo middleware to save the "X-Version-Id" from the headers to localStorage
 */
const backendVersionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext();
        const versionHeader = context.response.headers.get('X-Version-Id');
        if (typeof versionHeader === 'string') {
            window.localStorage.setItem(
                'ics-backend-api-version',
                versionHeader,
            );
        }
        return response;
    });
});

export default backendVersionLink;
