import { type ReactNode } from 'react';
import { Flex, Icon } from '@ics-portal/react/src/index';
import LinkButton from '~/components/LinkButton';
import { useMapContext } from '../../../_utils/map-context';

export function ProjectDetailsActions(): ReactNode {
    const { project } = useMapContext();
    if (!project) {
        return null;
    }
    return (
        <Flex direction="column" gap="md" ai="center">
            <LinkButton
                label="Go to project"
                rightIcon={<Icon name="arrow_forward" />}
                variant="primary-new"
                to={`/projects/${project.id}`}
            />
        </Flex>
    );
}
