import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';
import { type Project } from '~/gql/graphql';

interface MapContextProviderProps {
    children: ReactNode;
}

interface MapContextProps {
    project?: Project | null;
    setProject: (project: Project | null) => void;
}

const MapContext = createContext<MapContextProps | null>(null);

export function useMapContext(): MapContextProps {
    const context = useContext(MapContext);

    if (!context) {
        throw new Error('Map context must be used in <Map/>');
    }

    return context;
}

export function MapContextProvider({
    children,
}: MapContextProviderProps): ReactNode {
    const [project, setProject] = useState<Project | null | undefined>();

    return (
        <MapContext.Provider value={{ project, setProject }}>
            {children}
        </MapContext.Provider>
    );
}
