import { createContext, useContext } from 'react';
import type { useTooltip } from './use-tooltip';

type TooltipContextType = ReturnType<typeof useTooltip>;
type ContextType = TooltipContextType | null;

export const TooltipContext = createContext<ContextType | null>(null);

export function useTooltipContext(): TooltipContextType {
    const context = useContext(TooltipContext);

    if (!context) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
}
