import { useFloating, autoUpdate, arrow, offset, flip, shift, useHover, useFocus, useDismiss, useRole, useInteractions } from '@floating-ui/react';
import { useRef, useState, useMemo } from 'react';

/**
 * The default placement of the tooltip.
 * @remarks
 * The "flip" middleware will flip the tooltip to the opposite side if there is not enough space.
 */
const DEFAULT_PLACEMENT = 'top';
/**
 * The height of the arrow, see ./Content.tsx. for the arrow path.
 */
const ARROW_HEIGHT = 7;
/**
 * The gap between the trigger and the tooltip content.
 * @remarks
 * The actual distance is calculated in the "offset" middleware
 */
const GAP = 2;
/**
 * This hook provides the tooltip with the necessary interactions and floating logic.
 * it receives some options to customize the tooltip behavior passed down from the <Tooltip> component
 *
 * @internal This hooks is only meant to be used by the <Tooltip /> component, do not use it directly in any other place.
 */
function useTooltip() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    initialOpen,
    placement = DEFAULT_PLACEMENT,
    open: controlledOpen,
    onOpenChange: setControlledOpen
  } = options;
  const arrowRef = useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen !== null && controlledOpen !== void 0 ? controlledOpen : uncontrolledOpen;
  const setOpen = setControlledOpen !== null && setControlledOpen !== void 0 ? setControlledOpen : setUncontrolledOpen;
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
    /**
     * The arrow middleware is used to position the arrow element.
     * @see https://floating-ui.com/docs/FloatingArrow
     */
    arrow({
      element: arrowRef
    }),
    /**
     * The offset middleware translates the floating element along the specified axes.
     * @see https://floating-ui.com/docs/offset
     */
    offset(ARROW_HEIGHT + GAP),
    /**
     * The flip middleware changes the placement of the floating element to keep it in view.
     * @see https://floating-ui.com/docs/flip
     */
    flip({
      crossAxis: placement.includes('-'),
      fallbackAxisSideDirection: 'start',
      padding: 5
    }),
    /**
     * The shift middleware shifts the floating element to keep it in view.
     * @see https://floating-ui.com/docs/shift
     *
     * @remarks
     * This prevents the floating element from overflowing along its axis of alignment, thereby preserving the side it’s placed on.
     */
    shift({
      padding: 5
    })]
  });
  const context = data.context;
  /**
   * Interactions
   */
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen === undefined
  });
  const focus = useFocus(context, {
    enabled: controlledOpen === undefined
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip'
  });
  const interactions = useInteractions([hover, focus, dismiss, role]);
  return useMemo(() => Object.assign(Object.assign({
    open,
    setOpen,
    arrowRef,
    width: options.width
  }, interactions), data), [open, setOpen, arrowRef, interactions, data, options.width]);
}

export { useTooltip };
