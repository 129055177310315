import { useQuery } from '@apollo/client';
import { Card, Flex, GridTemplate } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { UPLOAD_ASSET_LIST } from '~/api/mutations/assets';
import {
    GET_ASSETS_COUNT_FOR_PROJECT,
    GET_COMPONENTS_COUNT_FOR_PROJECT,
    GET_NOTIFICATIONS_SUMMARY_IN_PROJECT,
    GET_ZONES_COUNT_FOR_PROJECT,
} from '~/api/queries/projects';
import ErrorMessage from '~/components/ErrorMessage';
import DownloadProjectAssetsButton from '~/components/_atoms/Download/DownloadProjectAssetsButton';
import { NorthEastIcon } from '~/components/_atoms/icons';
import UploadHandler from '~/components/_molecules/Upload/UploadHandler';
import type { Scalars } from '~/gql/graphql';
import CardDescription from '../CardDescription';

interface Props {
    pid: Scalars['ID']['input'];
}

export default function AssetsCard({ pid }: Props): JSX.Element {
    const { error, data } = useQuery(GET_ASSETS_COUNT_FOR_PROJECT, {
        fetchPolicy: 'cache-and-network',
        variables: { pid },
    });

    const assetsCount = data?.assetsInProject.totalCount!;
    const downloadLabel =
        assetsCount === 0 ? 'Download template' : 'Download assets';

    return (
        <Card backgroundColor="lightGrey" padding="lg">
            <GridTemplate col="minmax(0, 2fr) minmax(0, 1fr)">
                <Flex
                    ai="flex-start"
                    direction="column"
                    gap="sm"
                    jc="flex-start"
                >
                    <Link className="text-primary hv-text-link" to="assets">
                        <Flex display="inline-flex" gap="sm">
                            <h2>{assetsCount} assets</h2>
                            <NorthEastIcon />
                        </Flex>
                    </Link>
                    {assetsCount === 0 && (
                        <p className="m-none">
                            Download template to add assets, items and
                            components. Zones will also be included in the
                            template if defined prior to download.
                        </p>
                    )}
                    <CardDescription>
                        Assets are built of items. An item is an instance of a
                        global component. View assets and items. Use download
                        and import to add and edit.
                    </CardDescription>
                </Flex>
                <Flex ai="flex-end" direction="column" gap="sm" jc="flex-start">
                    <DownloadProjectAssetsButton
                        label={downloadLabel}
                        pid={pid}
                    />
                    <UploadHandler
                        label="Import assets"
                        mutation={UPLOAD_ASSET_LIST}
                        refetchQueries={[
                            {
                                query: GET_ASSETS_COUNT_FOR_PROJECT,
                                variables: { pid },
                            },
                            {
                                query: GET_NOTIFICATIONS_SUMMARY_IN_PROJECT,
                                variables: { pid },
                            },
                            {
                                query: GET_ZONES_COUNT_FOR_PROJECT,
                                variables: { pid },
                            },
                            {
                                query: GET_COMPONENTS_COUNT_FOR_PROJECT,
                                variables: { pid },
                            },
                        ]}
                        variables={{ pid }}
                    />
                </Flex>
            </GridTemplate>
            {error ? <ErrorMessage error={error} /> : null}
        </Card>
    );
}
