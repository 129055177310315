import { useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import { InfoCardDispatchContext, InfoCardStateContext } from './context';

interface InfoCardProviderProps {
    defaultOpen?: boolean;
    defaultToggleDisabled?: boolean;
    children: ReactNode;
}

export function InfoCardProvider({
    defaultOpen = false,
    defaultToggleDisabled = false,
    children,
}: InfoCardProviderProps): ReactNode {
    const [open, setOpen] = useState(defaultOpen);
    const [toggleDisabled, setToggleDisabled] = useState(defaultToggleDisabled);

    function toggle(): void {
        setOpen((prev) => !prev);
        if (open) {
            setToggleDisabled(false);
        }
    }

    useEffect(() => {
        setToggleDisabled(!open && defaultToggleDisabled);
    }, [defaultToggleDisabled, open]);

    return (
        <InfoCardStateContext.Provider value={{ open, toggleDisabled }}>
            <InfoCardDispatchContext.Provider
                value={{ setOpen, toggle, setToggleDisabled }}
            >
                {children}
            </InfoCardDispatchContext.Provider>
        </InfoCardStateContext.Provider>
    );
}
