import { useMap } from 'react-leaflet';
import { type ReactNode } from 'react';
import { Icon } from '@ics-portal/react';
import { BoxSwitch } from '../../_atoms/BoxSwitch/box-switch';

export function ZoomController(): ReactNode {
    const buttons = [
        {
            icon: <Icon name="add" fontSize="small" />,
            onClick: () => {
                map.setZoom(map.getZoom() + 1);
            },
        },
        {
            icon: <Icon name="remove" fontSize="small" />,
            onClick: () => {
                map.setZoom(map.getZoom() - 1);
            },
        },
    ];
    const map = useMap();

    return <BoxSwitch buttons={buttons} />;
}
