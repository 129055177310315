import { type User } from '~/gql/graphql';

/**
 * Any authorization strategy has to confirm to this protocol
 */
interface AuthorizationStrategy {
    isAuthorized: (viewer: User) => boolean;
}

/**
 * A authorization strategy that verifies if the user is an admin
 */
export class AdminAuthorizationStrategy implements AuthorizationStrategy {
    isAuthorized(viewer: User): boolean {
        return viewer.admin;
    }
}

/**
 * Check if the user is authorized according the provided strategy.
 * @param {User} viewer - The user to authorize.
 * @param {AuthorizationStrategy} strategy - The strategy to use.
 *
 * @returns {Boolean} if the user is authorized, false otherwise.
 */
export function isUserAuthorized(
    viewer: User,
    strategy: AuthorizationStrategy,
): boolean {
    return strategy.isAuthorized(viewer);
}

/**
 * Check if the user is an admin.
 * Uses the "AdminAuthorizationStrategy" to verify that the user is an admin.
 */
export function isUserAdmin(viewer: User): boolean {
    return isUserAuthorized(viewer, new AdminAuthorizationStrategy());
}
