import type { ReactNode } from 'react';
import { Pill } from '@ics-portal/react';
import { SurveyStatus } from '~/gql/graphql';

interface Props {
    status?: SurveyStatus;
}

function PSSStatusPill({ status }: Props) {
    return <Pill {...getPillPropsForPSS(status)} />;
}

export default PSSStatusPill;

const PILL_COLOR_FOR_PSS = {
    [SurveyStatus.Draft]: 'WM10',
    [SurveyStatus.Readyforapproval1]: 'WM30',
    [SurveyStatus.Readyforapproval2]: 'WM70',
    [SurveyStatus.Completed]: 'WM90',
    [SurveyStatus.Declined]: 'WM90',
} as const;

const getPillPropsForPSS = (
    status: SurveyStatus | undefined,
): {
    backgroundColor: 'WM10' | 'WM30' | 'WM70' | 'WM90' | 'N50';
    children: ReactNode;
} => {
    const backgroundColor = status ? PILL_COLOR_FOR_PSS[status] : 'WM10';

    if (status === SurveyStatus.Draft) {
        return {
            backgroundColor,
            children: 'Draft',
        };
    }
    if (status === SurveyStatus.Readyforapproval1) {
        return {
            backgroundColor,
            children: 'Ready for initial approval',
        };
    }
    if (status === SurveyStatus.Readyforapproval2) {
        return {
            backgroundColor,
            children: 'Ready for final approval',
        };
    }
    if (status === SurveyStatus.Completed) {
        return {
            backgroundColor,
            children: 'Completed',
        };
    }
    if (status === SurveyStatus.Declined) {
        return {
            backgroundColor: 'N50',
            children: 'Declined',
        };
    }
    return {
        backgroundColor: 'WM10',
        children: 'PSS',
    };
};
