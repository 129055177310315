import { type ReactNode } from 'react';

export enum MapControlPosition {
    TopLeft = 'topleft',
    TopRight = 'topright',
    BottomLeft = 'bottomleft',
    BottomRight = 'bottomright',
    Right = 'right',
}

export enum MapControlType {
    ZOOM = 'zoom',
}
export interface MapControlContainerProps {
    control: ReactNode | MapControlType;
    position: MapControlPosition;
}

export interface MapProps {
    controls?: MapControlContainerProps[];
    children?: ReactNode;
}
