import type { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ButtonProps } from '@ics-portal/react';
import { Button } from '@ics-portal/react';

type Props = {
    to: string;
    external?: boolean;
} & ButtonProps;

function LinkButton({ to, external = false, onClick, ...rest }: Props) {
    const navigate = useNavigate();

    const handleNavigation = (e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
        }
        if (external) {
            window.location.href = to;
        } else {
            navigate(to);
        }
    };

    return <Button {...rest} onClick={handleNavigation} role="link" />;
}

export default LinkButton;
