import { type ReactNode, useMemo } from 'react';
import { ProjectStatus } from '~/utils/project';

interface ProjectMarkerIconProps {
    status?: ProjectStatus | null;
    variant?: 'small' | 'large';
}

export function getMarkerIconColor(
    projectStatus?: ProjectStatus | null,
): string {
    switch (projectStatus) {
        case ProjectStatus.InProgress:
            return '#00FD79';
        case ProjectStatus.OnHold:
            return '#FFEF99';
        case ProjectStatus.Closed:
            return '#FF4A4A';
        default:
            return '#D7E4EE';
    }
}

export function getMarkerIconSvgString(
    color: string,
    variant: 'small' | 'large' = 'large',
): string {
    return `<svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            ${variant !== 'small' ? `<circle opacity="0.3" cx="12" cy="12" r="12" fill="${color}" />` : ''}
            ${variant !== 'small' ? `<circle opacity="0.4" cx="12" cy="12" r="9" fill="${color}" />` : ''}
            ${variant !== 'small' ? `<circle opacity="0.5" cx="12" cy="12" r="6" fill="${color}" />` : ''}
            <circle cx="12" cy="12" r="${variant === 'small' ? '6' : '3'}" fill="${color}" />
        </svg>`;
}

export function ProjectMarkerIcon({
    status,
    variant = 'large',
}: ProjectMarkerIconProps): ReactNode {
    const color = useMemo(() => {
        return getMarkerIconColor(status);
    }, [status]);

    return (
        <span
            style={{ lineHeight: 0 }}
            dangerouslySetInnerHTML={{
                __html: getMarkerIconSvgString(color, variant),
            }}
        />
    );
}
