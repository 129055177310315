import { useQuery } from '@apollo/client';
import { Card, Flex, GridTemplate } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { GET_ZONES_COUNT_FOR_PROJECT } from '~/api/queries/projects';
import ErrorMessage from '~/components/ErrorMessage';
import { NorthEastIcon } from '~/components/_atoms/icons';
import type { Scalars } from '~/gql/graphql';
import CardDescription from '../CardDescription';

interface Props {
    pid: Scalars['ID']['input'];
}

export default function SecurityZonesCard({ pid }: Props): JSX.Element {
    const { error, data } = useQuery(GET_ZONES_COUNT_FOR_PROJECT, {
        fetchPolicy: 'cache-and-network',
        variables: { pid },
    });

    const zones = data?.projectZones.totalCount || null;

    return (
        <Card backgroundColor="lightGrey" padding="lg">
            <GridTemplate col="minmax(0, 2fr) minmax(0, 1fr)">
                <Flex ai="flex-start" direction="column" gap="sm">
                    <Link
                        className="text-primary hv-text-link"
                        to="security-zones"
                    >
                        <Flex display="inline-flex" gap="sm">
                            <h2>{zones} security zones</h2>
                            <NorthEastIcon />
                        </Flex>
                        {error ? <ErrorMessage error={error} /> : null}
                    </Link>
                    {zones === 1 && (
                        <p className="m-none">
                            Only the default zone is defined. Add more zones as
                            needed.
                        </p>
                    )}
                    {zones ? (
                        <CardDescription>
                            View the project security zones. You can add and
                            edit security zones, view assigned assets, and
                            define and evaluate security standards and
                            requirements for the zones.
                        </CardDescription>
                    ) : null}
                </Flex>
            </GridTemplate>
        </Card>
    );
}
