import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_PROJECTS } from '~/api/queries/projects';
import type { Project } from '~/gql/graphql';
import { OrderDirection } from '~/gql/graphql';
import ErrorMessage from '~/components/ErrorMessage';
import { useInfoCard } from '~/components/InfoCard';

export enum CardState {
    Summary,
    View,
    Create,
    Edit,
    Copy,
}
export enum DisplayMode {
    Cards = 'cards',
    Grid = 'grid',
}

interface ProjectContextValues {
    loading: boolean;
    projects: Project[] | undefined;
    selected: Project[];
    setSelected: Dispatch<SetStateAction<Project[]>>;
    cardState: CardState;
    setCardState: Dispatch<SetStateAction<CardState>>;
    displayMode: DisplayMode;
    setDisplayMode: Dispatch<SetStateAction<DisplayMode>>;
    expandInfoCard: () => void;
}

const ProjectsContext = createContext<ProjectContextValues>({
    loading: false,
    projects: [],
    selected: [],
    setSelected: () => null,
    cardState: CardState.Summary,
    setCardState: () => null,
    displayMode: DisplayMode.Cards,
    setDisplayMode: () => null,
    expandInfoCard: () => null,
});

interface ProjectsProviderProps {
    children: ReactNode;
}

export function ProjectsProvider({ children }: ProjectsProviderProps) {
    const { setOpen } = useInfoCard();
    const { loading, error, data } = useQuery(GET_ALL_PROJECTS, {
        variables: { direction: OrderDirection.Asc },
        fetchPolicy: 'cache-and-network',
    });
    const [selected, setSelected] = useState<Project[]>([]);
    const [cardState, setCardState] = useState<CardState>(CardState.Summary);
    const [displayMode, setDisplayMode] = useState<DisplayMode>(
        DisplayMode.Cards,
    );

    const expandInfoCard = (): void => {
        setOpen(true);
    };

    const projects = useMemo(
        () => data?.projects?.edges.map((edge) => edge.node) ?? [],
        [data],
    );

    if (error) {
        return <ErrorMessage error={error} />;
    }

    return (
        <ProjectsContext.Provider
            value={{
                loading,
                projects,
                selected,
                setSelected,
                cardState,
                setCardState,
                displayMode,
                setDisplayMode,
                expandInfoCard,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    );
}

export default ProjectsContext;
