import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';

/**
 * A button that contains an icon
 */
function IconButton(_a) {
  var {
      className,
      type = 'button',
      disabled = false,
      variant,
      children
    } = _a,
    rest = __rest(_a, ["className", "type", "disabled", "variant", "children"]);
  const buttonClasses = ['ics-icon-button'];
  if (className) buttonClasses.push(className);
  if (variant === 'simple') buttonClasses.push('ics-icon-button--simple');
  if (variant === 'ghost') buttonClasses.push('ics-icon-button--ghost');
  const combinedClassName = buttonClasses.join(' ');
  return jsx("div", {
    className: "ics-icon-button__container",
    children: jsx("button", Object.assign({
      className: combinedClassName,
      disabled: disabled,
      type: type
    }, rest, {
      children: children
    }))
  });
}

export { IconButton };
