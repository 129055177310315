import type { HTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';

interface FormGroupProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode | ReactNode[];

    /**
     * The class name of the form group component.
     */
    className?: string;
}

/**
 * FormGroup component is a container for form elements. Group related elements such as label and select together to provide a better user experience.
 */
export default function FormGroup({
    className,
    children,
}: FormGroupProps): ReactNode {
    const classes = clsx('ics-form-group', className);
    return <div className={classes}>{children}</div>;
}
