// import './iconButton.css';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

export interface IconButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    /**
     * Children
     */
    children: ReactNode;

    /**
     * class to apply to the inner button
     */
    className?: string;

    /**
     * type of the button
     */
    type?: 'button' | 'submit' | 'reset';

    /*
     * Apply style variant
     */
    variant?: 'simple' | 'ghost';
}

/**
 * A button that contains an icon
 */
export function IconButton({
    className,
    type = 'button',
    disabled = false,
    variant,
    children,
    ...rest
}: IconButtonProps) {
    const buttonClasses = ['ics-icon-button'];
    if (className) buttonClasses.push(className);
    if (variant === 'simple') buttonClasses.push('ics-icon-button--simple');
    if (variant === 'ghost') buttonClasses.push('ics-icon-button--ghost');
    const combinedClassName = buttonClasses.join(' ');
    return (
        <div className="ics-icon-button__container">
            <button
                className={combinedClassName}
                disabled={disabled}
                type={type}
                {...rest}
            >
                {children}
            </button>
        </div>
    );
}
