import { useMergeRefs } from '@floating-ui/react';
import type { Attributes, HTMLProps, ReactElement, RefObject } from 'react';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { useTooltipContext } from './context';

type ElementWithRef = ReactElement & { ref: RefObject<unknown> };

interface TriggerProps extends HTMLProps<HTMLElement> {
    asChild?: boolean;
}

export const TooltipTrigger = forwardRef<HTMLElement, TriggerProps>(
    function TooltipTrigger(props, forwardedRef) {
        const { children, asChild = false, ...rest } = props;
        const context = useTooltipContext();
        const childrenRef = (children as ElementWithRef).ref;
        const ref = useMergeRefs([
            context.refs.setReference,
            childrenRef,
            forwardedRef,
        ]);

        // `asChild` allows the user to pass any element as the anchor
        if (asChild && isValidElement(children)) {
            const childProps: Attributes = context.getReferenceProps({
                ref,
                ...rest,
                ...children.props,
                'data-state': context.open ? 'open' : 'closed',
            });
            return cloneElement(children, childProps);
        }

        return (
            <span
                ref={ref}
                // The user can style the trigger based on the state
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}
            >
                {children}
            </span>
        );
    },
);
