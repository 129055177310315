/**
 * Root Error Page
 * A generic error page that display a user friendly error message.
 * E.g. It catches any error thrown during an action, loader, or rendering.
 * @see https://reactrouter.com/en/main/route/error-element
 * @see https://reactrouter.com/en/main/hooks/use-route-error
 */

import { Button } from '@ics-portal/react';
import {
    isRouteErrorResponse,
    NavLink,
    useNavigate,
    useRouteError,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import s from './ErrorPage.module.css';

interface CustomErrorResponse {
    errorCode: number;
    title: string;
    description: string;
    backToUrl: string;
}

function getErrorResponse(statusCode: number): CustomErrorResponse {
    switch (statusCode) {
        case 403:
            return {
                errorCode: 403,
                title: 'Unauthorized access',
                description:
                    'You do not have permission to view this page/resource.',
                backToUrl: '/',
            };

        case 404:
            return {
                errorCode: 404,
                title: 'Page not found',
                description: 'The page you are looking for does not exist.',
                backToUrl: '/',
            };

        default:
            return {
                errorCode: statusCode,
                title: 'Uknown error',
                description:
                    'An unknown error has occurred. If you keep seeing this issue, please contact support.',
                backToUrl: '/',
            };
    }
}

function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    // Handle any Response thrown in a loader or action
    if (isRouteErrorResponse(error)) {
        const { errorCode, title, description, backToUrl } = getErrorResponse(
            error.status,
        );

        return (
            <div className={s.pageWrapper}>
                <div>
                    <h1>
                        <span className={s.number}>{errorCode}</span>
                        <span>{title}</span>
                    </h1>
                    <p>
                        <span>{description}</span>
                    </p>
                    <NavLink
                        className={s.backButton}
                        to={backToUrl}
                        role="link"
                    >
                        Go back to start
                    </NavLink>
                </div>
            </div>
        );
    }

    if (error instanceof Error) {
        return (
            <div className={s.pageWrapper}>
                <div>
                    <h1>
                        <span className={s.number}>Unknown error</span>
                        <span>
                            Something unexpected occured, try and reload the
                            browser or click the button to go back.
                        </span>
                    </h1>
                    <p>
                        <span>{error.message}</span>
                    </p>
                    <Button
                        className={s.backButton}
                        label="Go back"
                        onClick={() => {
                            navigate(-1);
                        }}
                        role="link"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={s.pageWrapper}>
            <div>
                <h1>
                    <span className={s.number}>Unknown error</span>
                    <span>
                        Something unexpected occured, try and reload the browser
                        or click the button to go back.
                    </span>
                </h1>
                <Button
                    className={s.backButton}
                    label="Go back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    role="link"
                />
            </div>
        </div>
    );
}

export default ErrorPage;
