import type { CSSProperties, ReactNode } from 'react';

export interface FlexProps {
    /**
     * Override the display mode, useful if you are creating a child component that you don't want to be flex
     */
    display?: 'inline-flex' | 'block' | 'inline';
    /**
     * Justify content
     */
    jc?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'initial'
        | 'stretch'
        | 'inherit';
    /**
     * Align items
     */
    ai?:
        | 'stretch'
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'baseline'
        | 'initial'
        | 'inherit';
    /**
     * Flex direction
     */
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    /**
     * Flex wrap
     */
    fw?: 'wrap' | 'nowrap' | 'wrap-reverse';
    /**
     * Gap according to style guide
     */
    gap?: 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    /**
     * Flex grow
     */
    grow?: number;
    /**
     * Flex shrink
     */
    shrink?: number;
    /**
     * Flex basis
     */
    basis?: number;
    /**
     * Flex shorthand
     */
    flex?: string;
    /**
     * Children
     */
    children?: ReactNode;
    /**
     * Class
     */
    className?: string;
    /**
     * Set height of the component
     */
    height?: string;
    /**
     * Set width of the component
     */
    width?: string;
    /**
     * Set max height of the component
     */
    maxHeight?: string;
    /**
     * Set max width of the component
     */
    maxWidth?: string;
    /**
     * Set min height of the component
     */
    minHeight?: string;
    /**
     * Set min width of the component
     */
    minWidth?: string;
    /**
     * Allows manually configuring style elements
     */
    style?: CSSProperties;
}

/**
 * The Flex component is a useful layout utility component for creating fine-grained control over layouts using the CSS Flexbox display mode.
 *
 * This allows for common layout patterns to be easily created without needing to write custom CSS. In the case of the Flex component,
 * it uses the old gutter-spacing system, where the Stack component uses a more formal spacing scale.
 *
 * If you are creating a layout that only requires spacing between elements, consider using the simpler Stack component instead.
 */
export function Flex({
    children,
    className,
    style,
    ...props
}: FlexProps): ReactNode {
    return (
        <div
            className={className}
            style={{
                display: !props.display ? 'flex' : props.display,
                justifyContent: props.jc ?? 'space-between',
                flexDirection: props.direction ?? 'row',
                flexGrow: props.grow ?? 0,
                flexBasis: props.basis ?? 'auto',
                flexShrink: props.shrink ?? 1,
                flexWrap: props.fw ?? 'nowrap',
                alignItems: props.ai ?? 'center',
                gap: props.gap ? `var(--ics-gutter-${props.gap})` : '0',
                width: props.width ?? 'auto',
                height: props.height ?? 'auto',
                maxWidth: props.maxWidth ?? 'none',
                maxHeight: props.maxHeight ?? 'none',
                minWidth: props.minWidth ?? 'none',
                minHeight: props.minHeight ?? 'none',
                boxSizing: 'border-box',
                ...style,
            }}
        >
            {children}
        </div>
    );
}
