import { ME } from '~/api/queries/users';
import { isUserAdmin } from '~/auth/authorization';
import type { GetMeQuery } from '~/gql/graphql';
import apolloClient from '~/api/client';

async function adminLoader(): Promise<Response> {
    const response = await apolloClient.query<GetMeQuery>({
        query: ME,
    });

    if (!isUserAdmin(response.data.me)) {
        throw new Response('Unauthorized Access', { status: 403 });
    }

    return new Response('', {
        status: 200,
    });
}

export default adminLoader;
