import type {
    ChangeEvent,
    CSSProperties,
    DetailedHTMLProps,
    TextareaHTMLAttributes,
} from 'react';
import { forwardRef } from 'react';
// import './TextArea.css';

export interface TextAreaProps
    extends DetailedHTMLProps<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    > {
    /**
     * Unique identifier
     */
    id: string;
    /**
     * The input field name
     */
    name: string;
    /**
     * Label text
     */
    label?: string;
    /**
     * Input field placeholder
     */
    placeholder?: string;
    /**
     * Input field value (this input is controlled only for now)
     */
    value?: string;
    /**
     * onChange event handler function
     */
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    /**
     * Hide the label visually. It will still be readable for screen readers
     */
    hideLabelVisually?: boolean;
    /**
     * Hides the error message space below, allowing for a closer focus ring
     */
    hideErrorMessage?: boolean;
    /**
     * Additional custom styles (overrides)
     */
    style?: CSSProperties;
    /**
     * className string, allowing to extend the component
     */
    className?: string;
    /**
     * Disables the question
     */
    disabled?: boolean;
    /**
     * Error message to be displayed
     */
    errorMessage?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    function TextArea(props, ref) {
        const {
            id,
            name,
            label = name,
            placeholder = 'Your text here...',
            value,
            onChange,
            hideLabelVisually = false,
            style,
            className,
            disabled,
            errorMessage,
            hideErrorMessage,
            ...restProps
        } = props;

        const classes = ['ics-text-area-compact', className].join(' ');

        return (
            <div className={classes} style={style}>
                {label ? (
                    <label
                        className={hideLabelVisually ? ' ics-sr-only' : ''}
                        htmlFor={name}
                    >
                        {label}
                    </label>
                ) : null}
                <textarea
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    ref={ref}
                    value={value}
                    {...restProps}
                />
                {!hideErrorMessage && (
                    <div className="ics-text-area__error-container">
                        {errorMessage}
                    </div>
                )}
            </div>
        );
    },
);

TextArea.displayName = 'TextArea';
