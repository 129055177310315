import type { ReactNode } from 'react';
import { Card, LoadingBlur } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import {
    AdminMappingsIcons,
    AdminPSSProjectClassificationTemplatesIcons,
    AdminRequirementsIcons,
    AdminStandardsIcons,
    AdminUsersIcons,
} from '~/components/_atoms/icons';
import TitleWithHelpLink from '~/components/_molecules/TitleWithHelpLink';
import ContentController from '~/components/_organisms/Headers/ContentController';
import { InfoCardTemplate } from '~/components/_templates/InfoCardTemplate';
import s from './AdminDashboardPage.module.css';

function AdminStart(): ReactNode {
    return (
        <LoadingBlur loading={false} style={{ width: '100%' }}>
            <InfoCardTemplate>
                <ContentController
                    title={
                        <TitleWithHelpLink
                            label="Admin"
                            link="https://wiki.siemens-energy.com/x/9Yf3FQ"
                        />
                    }
                />
                <div className="content-area p-content">
                    <nav aria-label="Admin menu">
                        <div className={s.grid}>
                            <div className={s.gridItem}>
                                <Link to="users">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminUsersIcons />
                                        <h2>Users</h2>
                                    </Card>
                                </Link>
                            </div>
                            <div className={s.gridItem}>
                                <Link to="standards">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminStandardsIcons />
                                        <h2>Standards</h2>
                                    </Card>
                                </Link>
                            </div>
                            <div className={s.gridItem}>
                                <Link to="requirements">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminRequirementsIcons />
                                        <h2>Requirements</h2>
                                    </Card>
                                </Link>
                            </div>
                            <div className={s.gridItem}>
                                <Link to="mappings">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminMappingsIcons />
                                        <h2>Mappings</h2>
                                    </Card>
                                </Link>
                            </div>
                            <div className={s.gridItem}>
                                <Link to="pss">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminPSSProjectClassificationTemplatesIcons />
                                        <h2>
                                            PSS project classification templates
                                        </h2>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </InfoCardTemplate>
        </LoadingBlur>
    );
}

export default AdminStart;
