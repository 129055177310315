import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { clsx } from 'clsx';
import { Icon } from '../Icon';
import { DrawerContext } from './context';

interface DrawerProps {
    /**
     * The modality of the dialog.
     * When set to true, interaction with outside elements will be disabled and only dialog content will be visible to screen readers.
     * @defaultValue true
     */
    modal?: boolean;
    /**
     * The controlled open state of the dialog. Must be used in conjunction with onOpenChange.
     */
    open?: boolean;
    /**
     * Event handler called when the open state of the dialog changes.
     */
    onOpenChange?: (open: boolean) => void;
    /**
     * The content of the dialog.
     */
    children: ReactNode;
}

function Root({
    modal = true,
    open,
    onOpenChange,
    children,
}: DrawerProps): ReactNode {
    function handleOnOpenChange(o: boolean): void {
        if (typeof onOpenChange === 'function') {
            onOpenChange(o);
        }
    }

    return (
        <DialogPrimitive.Root
            modal={modal}
            open={open}
            onOpenChange={handleOnOpenChange}
        >
            <DrawerContext.Provider value={{ modal, open, onOpenChange }}>
                {children}
            </DrawerContext.Provider>
        </DialogPrimitive.Root>
    );
}

type ContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content>;

const Content = forwardRef<HTMLDivElement, ContentProps>(
    function ContentComponent({ className, ...rest }, ref) {
        const classes = clsx('ics-drawer', className);
        return (
            <DialogPrimitive.Content ref={ref} className={classes} {...rest} />
        );
    },
);

Content.displayName = 'DrawerContent';

const Overlay = forwardRef<
    HTMLDivElement,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(function OverlayComponent({ children, className, ...rest }, ref) {
    const classes = clsx('ics-drawer__overlay', className);
    return <DialogPrimitive.Overlay ref={ref} className={classes} {...rest} />;
});

Overlay.displayName = 'DrawerOverlay';

const Close = forwardRef<
    HTMLButtonElement,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(function CloseComponent({ className, ...rest }, ref) {
    const classes = clsx('ics-drawer__close', className);
    return (
        <DialogPrimitive.Close ref={ref} className={classes} {...rest}>
            <Icon name="close" />
        </DialogPrimitive.Close>
    );
});

function Portal({
    children,
    ...rest
}: ComponentPropsWithoutRef<typeof DialogPrimitive.Portal>): ReactNode {
    return (
        <DialogPrimitive.Portal {...rest}>{children}</DialogPrimitive.Portal>
    );
}

export const Drawer = Root;
export const DrawerClose = Close;
DrawerClose.displayName = 'DrawerClose';
export const DrawerContent = Content;
DrawerContent.displayName = 'DrawerContent';
export const DrawerDescription = DialogPrimitive.Description;
DrawerDescription.displayName = 'DrawerDescription';
export const DrawerOverlay = Overlay;
DrawerOverlay.displayName = 'DrawerOverlay';
export const DrawerPortal = Portal;
export const DrawerTitle = DialogPrimitive.Title;
DrawerTitle.displayName = 'DrawerTitle';
export const DrawerTrigger = DialogPrimitive.Trigger;
DrawerTrigger.displayName = 'DrawerTrigger';
