import { SendFeedbackIcon } from '~/components/_atoms/icons';
import type { Navigation } from '../../interfaces/IMenuData';

const options = {
    to: import.meta.env.VITE_FEEDBACK_RECIPIENT,
    subject: `SE ICS - bug report/feedback from [${window.location.hostname}]`,
    body: `I have found a bug/I want to suggest something!%0D%0A%0D%0A
BUG:%0D%0A
What I did: DESCRIBE%0D%0A
What happened: DESCRIBE%0D%0A
What I expected: DESCRIBE%0D%0A
---------%0D%0A
Feedback/suggestion:%0D%0A
INPUT%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A
---------%0D%0A
URL: ${window.document.URL}%0D%0A
Time: ${new Date().toLocaleString()}%0D%0A
User Agent: ${navigator.userAgent}%0D%0A`,
};

const SecondaryNavigation: Navigation = {
    id: 'secondary',
    items: [
        {
            label: 'Send feedback/report a bug',
            to: `mailto:${options.to}?subject=${options.subject}&body=${options.body}`,
            icon: {
                default: SendFeedbackIcon,
            },
            admin: false,
        },
    ],
};

export default SecondaryNavigation;
