import { graphql } from '~/gql';

export const GET_ALL_PROJECTS = graphql(`
    query GetProjects(
        $creatorId: ID
        $field: ProjectOrderField
        $direction: OrderDirection!
    ) {
        projects(
            where: { creatorID: $creatorId }
            orderBy: { field: $field, direction: $direction }
        ) {
            totalCount
            edges {
                node {
                    id
                    name
                    description
                    creator {
                        id
                        gid
                        firstName
                        lastName
                        email
                    }
                    organization {
                        id
                        name
                    }
                    phase {
                        id
                        name
                    }
                    status {
                        id
                        name
                    }
                    type {
                        id
                        name
                    }
                    ...ProjectCustomerPartial
                    ...ProjectLocationPartial
                    ...ProjectPlantPartial
                    updateTime
                    createTime
                }
            }
        }
    }
`);

export const GET_PROJECT_ZONES_IN_PROJECT = graphql(`
    query GetProjectZonesInProject($pid: ID!) {
        projectZones(
            where: { projectID: $pid }
            orderBy: { field: NAME, direction: ASC }
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`);

export const GET_UNORDERED_PROJECT_ZONES_IN_PROJECT = graphql(`
    query GetUnorderedProjectZonesInProject($pid: ID!) {
        projectZones(where: { projectID: $pid }) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`);

export const GET_ALL_ZONES_IN_PROJECT = graphql(`
    query listProjectZones($pid: ID!) {
        projectZones(where: { projectID: $pid }) {
            edges {
                node {
                    id
                    name
                    description
                    parentZone {
                        id
                        name
                    }
                    exposureRating
                    createTime
                    updateTime
                }
            }
        }
    }
`);

export const GET_PROJECT_COMPONENTS = graphql(`
    query GetProjectComponents(
        $pid: ID!
        $orderBy: ComponentInProjectOrder
        $where: ComponentInProjectWhereInput
        $first: Int
        $before: Cursor
        $after: Cursor
    ) {
        componentsInProject(
            projectID: $pid
            orderBy: $orderBy
            where: $where
            first: $first
            before: $before
            after: $after
        ) {
            edges {
                node {
                    id
                    name
                    componentVendorName
                    version
                    isValid
                    cpeName
                    eolDate
                    eolReached
                    monitoredSince
                    usedInProjectCount
                    url
                    securityURL
                }
            }
            totalCount
            pageInfo {
                startCursor
                endCursor
            }
        }
    }
`);

export const GET_PROJECTS_AFFECTED_BY_NOTIFICATION = graphql(`
    query GetProjectsAffectedByNotification($notificationId: ID!) {
        projectsAffectedByNotification(notificationID: $notificationId) {
            edges {
                node {
                    id
                    name
                    description
                    affectedComponentsCount
                    affectedAssetsCount
                }
            }
        }
    }
`);

export const GET_NOTIFICATIONS_SUMMARY_IN_PROJECT = graphql(`
    query GetNotificationsSummaryInProject($pid: ID!) {
        notificationsSummaryInProject(projectID: $pid) {
            priority
            total
            componentsTotal
        }
    }
`);
export const GET_SINGLE_PROJECT_NAME_ONLY = graphql(`
    query GetProjectName($pid: ID!) {
        projects(where: { id: $pid }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);

export const GET_ZONES_COUNT_FOR_PROJECT = graphql(`
    query GetZonesCountForProject($pid: ID!) {
        projectZones(where: { projectID: $pid }) {
            totalCount
        }
    }
`);

export const GET_ASSETS_COUNT_FOR_PROJECT = graphql(`
    query GetAssetsCountForProject($pid: ID!) {
        assetsInProject(projectID: $pid) {
            totalCount
        }
    }
`);

export const GET_COMPONENTS_COUNT_FOR_PROJECT = graphql(`
    query GetComponentsCountForProject($pid: ID!) {
        componentsInProject(projectID: $pid) {
            totalCount
        }
    }
`);

export const GET_PROJECT = graphql(`
    query GetProjectDetails($pid: ID!) {
        projects(where: { id: $pid }) {
            edges {
                node {
                    ...ProjectPartial
                }
            }
        }
    }
`);

export const GET_PARTICIPANTS_FOR_PROJECT = graphql(`
    query GetParticipantsForProject($pid: ID!) {
        projects(where: { id: $pid }) {
            edges {
                node {
                    id
                    name
                    creator {
                        id
                    }
                    organization {
                        id
                        name
                    }
                    description
                    notifications {
                        totalCount
                    }
                    participants {
                        ... on ProjectUser {
                            id
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            role {
                                id
                                name
                            }
                        }
                        ... on OrganizationUser {
                            id
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            organization {
                                id
                                name
                            }
                            organizationRole: role
                        }
                    }
                }
            }
        }
    }
`);

export const GET_USERS_FOR_PROJECT = graphql(`
    query GetUsersForProject($pid: ID!) {
        projects(where: { id: $pid }) {
            edges {
                node {
                    users {
                        ...ProjectUserPartial
                    }
                }
            }
        }
    }
`);

export const RelatedFormFieldQuery = graphql(`
    query ProjectFormFields {
        organizations {
            edges {
                node {
                    id
                    name
                }
            }
        }
        projectStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
        projectTypes {
            edges {
                node {
                    id
                    name
                }
            }
        }
        projectPhases {
            edges {
                node {
                    id
                    name
                    seqNo
                }
            }
        }
        users {
            edges {
                node {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`);

export const GET_PROJECT_PHASES_BY_PROJECT_TYPE_ID = graphql(`
    query GetProjectPhasesByProjectTypeId($projectTypeId: ID!) {
        projectPhases(where: { projectTypeID: $projectTypeId }) {
            edges {
                node {
                    id
                    name
                    seqNo
                }
            }
        }
    }
`);

export const GET_PROJECT_STATUSES_AND_PHASES = graphql(`
    query GetProjectStatusesAndPhases($projectTypeId: ID!) {
        projectStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
        projectPhases(where: { projectTypeID: $projectTypeId }) {
            edges {
                node {
                    id
                    name
                    seqNo
                }
            }
        }
    }
`);

export const GET_PROJECTS_STATS = graphql(`
    query projectsStats($pid: ID!) {
        pendingTasks: tasks(
            where: {
                and: [{ projectID: $pid }, { not: { status: COMPLETED } }]
            }
        ) {
            totalCount
        }
        assets(where: { hasProjectZoneWith: { projectID: $pid } }) {
            totalCount
        }
        components: componentsInProject(projectID: $pid) {
            totalCount
        }
    }
`);
