import { useContext, useRef, useEffect, createContext } from 'react';
import type { ReactNode } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { useRadioGroup, useRadio } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import type { RadioGroupState } from '@react-stately/radio';
import type { RadioGroupProps, AriaRadioProps } from '@react-types/radio';
// import './radioButtons.css';

interface RadioButtonsProps {
    /**
     * Label text for the radio group
     */
    label?: ReactNode;
    /**
     * Hide the label visually. It will still be readable for screen readers
     */
    hideLabelVisually?: boolean;
    /**
     * Radio button options
     */
    options: {
        label: string;
        value: string | number;
        disabled?: boolean;
    }[];
    /**
     * Unique identifier
     */
    id?: string;
    /**
     * Pass value for pre-controlled component. If a value is passed, you also need to pass an onChange
     */
    value?: string;
    /**
     * onChange event handler
     */
    onChange?: (checkedValue: string) => void;
    /**
     * Add additional classes to the container
     */
    className?: string;
    /**
     * Radio button sizes
     */
    size?: 'md' | 'sm';
}

const RadioContext = createContext<RadioGroupState | null>(null);

interface GroupProps extends RadioGroupProps {
    children: ReactNode;
    hideLabelVisually?: boolean;
    id?: string;
    value?: string;
    defaultValue?: string;
}

function RadioGroup(props: GroupProps & { className?: string }): ReactNode {
    const { children, label, value, onChange, ...rest } = props;
    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps } = useRadioGroup(props, state);

    useEffect(() => {
        if (props.value) {
            state.setSelectedValue(props.value);
        }
    }, [props.value, state]);

    useEffect(() => {
        if (typeof onChange === 'function' && state.selectedValue) {
            onChange(state.selectedValue);
        }
    }, [onChange, state.selectedValue]);

    return (
        <div {...radioGroupProps} className="ics-radio-group" {...rest}>
            {Boolean(label) && (
                <span
                    {...labelProps}
                    className={
                        props.hideLabelVisually
                            ? 'ics-sr-only'
                            : 'ics-radio-group__label'
                    }
                >
                    {label}
                </span>
            )}
            <RadioContext.Provider value={state}>
                {children}
            </RadioContext.Provider>
        </div>
    );
}

export function Radio(props: AriaRadioProps & { size?: 'md' | 'sm' }) {
    const { children } = props;
    const state = useContext(RadioContext);
    const ref = useRef(null);
    const { inputProps } = useRadio(props, state!, ref);
    const { isFocusVisible, focusProps } = useFocusRing();

    const isSelected = state && state.selectedValue === props.value;
    const strokeWidth = isSelected ? 4 : 1;
    let cN = isSelected
        ? 'ics-radio-group-radio ics-radio-group-radio--selected'
        : 'ics-radio-group-radio';
    if (props.isDisabled) {
        cN = `${cN} ics-radio-group-radio--disabled`;
    }

    if (props.size === 'sm') {
        cN = `${cN} ics-radio-group-radio--sm`;
    }

    const circleSize = props.size === 'sm' ? 16 : 24;
    const innerCircleSize = props.size === 'sm' ? 8 : 12;
    const innerCircleOffsetSize = innerCircleSize - 1;

    return (
        <label className={cN}>
            <VisuallyHidden>
                <input {...inputProps} {...focusProps} ref={ref} />
            </VisuallyHidden>
            <svg aria-hidden="true" height={circleSize} width={circleSize}>
                <circle
                    className="ics-radio-group-radio__circle"
                    cx={innerCircleSize}
                    cy={innerCircleSize}
                    r={innerCircleOffsetSize - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                />
                {isFocusVisible ? (
                    <circle
                        className="ics-radio-group-radio__focus-ring"
                        cx={innerCircleSize}
                        cy={innerCircleSize}
                        fill="none"
                        r={innerCircleOffsetSize}
                        strokeWidth={2}
                    />
                ) : null}
            </svg>
            {children}
        </label>
    );
}

export function RadioButtons({
    label,
    hideLabelVisually,
    options,
    id,
    value,
    onChange,
    className,
    size,
}: RadioButtonsProps) {
    return (
        <RadioGroup
            className={className}
            hideLabelVisually={hideLabelVisually}
            id={id ?? id}
            label={label}
            onChange={onChange}
            value={value ?? value}
        >
            {options.map((option) => (
                <Radio
                    isDisabled={option.disabled}
                    key={`radioKey${option.value}`}
                    size={size}
                    value={String(option.value)}
                >
                    {option.label}
                </Radio>
            ))}
        </RadioGroup>
    );
}
