import React, { type ReactNode, useMemo } from 'react';
import { type MapControlContainerProps, MapControlPosition } from './types';
import s from './map.module.css';

export function MapControlContainer({
    control,
    position,
}: MapControlContainerProps): ReactNode {
    const className = useMemo(() => {
        switch (position) {
            case MapControlPosition.BottomLeft:
                return s.bottomLeft;
            case MapControlPosition.BottomRight:
                return s.bottomRight;
            case MapControlPosition.TopLeft:
                return s.topLeft;
            case MapControlPosition.TopRight:
                return s.topRight;
            case MapControlPosition.Right:
                return s.right;
            default:
                return '';
        }
    }, [position]);
    return (
        <div className={`${className} ${s.mapControlContainer}`}>{control}</div>
    );
}
