import { type ReactNode } from 'react';
import {
    ProjectTypeCustomerIcon,
    ProjectTypeDefaultIcon,
    ProjectTypeFactoryLifecycleIcon,
    ProjectTypeProductDevelopmentIcon,
    ProjectTypeServiceIcon,
    ProjectTypeStandardSolutionIcon,
} from '~/components/_atoms/icons';

/**
 *
 */
export enum ProjectStatus {
    InProgress = 'in progress',
    OnHold = 'on hold',
    Open = 'open',
    Closed = 'closed',
}

// ID for different project types (// -> project.type.id)
export const ProjectIcons: Record<string, ReactNode> = {
    // Customer Project (PE@SE)
    PT01G6JHK1162M5N4JH0KGHY9Z04: <ProjectTypeCustomerIcon />,
    // Product development Project (PE@SE)
    PT01G6JHNRC91127SVP0BMD75FSY: <ProjectTypeProductDevelopmentIcon />,
    // Standard solution Project (PE@SE)
    PT01G6JJ8WTE07Y3FRNS5DJF88KX: <ProjectTypeStandardSolutionIcon />,
    // Service Project (PE@SE)
    PT01G6N9MCZGC6QAYTHRTTJKBYV8: <ProjectTypeServiceIcon />,
    // Factory lifecycle Project (PE@SE)
    PT01G6NCP2KDSW4Q2B3160N0VSKQ: <ProjectTypeFactoryLifecycleIcon />,
};

/**
 * Get the icon for a project type
 * fallback to MiscellaneousServicesOutlined if the id is not found in the projectIcons object
 * @param id - the id of the project type
 */
export function getProjectIcon(id: string | undefined): ReactNode {
    if (id && Object.keys(ProjectIcons).includes(id)) {
        return ProjectIcons[id];
    }
    return <ProjectTypeDefaultIcon />;
}
