import { graphql } from '~/gql';

export const GET_PROJECT_ASSETS = graphql(`
    query GetProjectAssets($pid: ID!) {
        assets(where: { hasProjectZoneWith: { projectID: $pid } }) {
            totalCount
            edges {
                node {
                    ...AssetFragment
                }
            }
        }
    }
`);

export const GET_ASSETS_IN_PROJECT_ZONE = graphql(`
    query GetAssetsInProjectZone($pzid: ID!) {
        assets(where: { projectZoneID: $pzid }) {
            edges {
                node {
                    id
                    name
                    description
                    alias
                    status
                    systemRating
                    projectZone {
                        name
                    }
                    assetType {
                        name
                    }
                    createTime
                    updateTime
                }
            }
        }
    }
`);

export const GET_ASSET_ITEMS = graphql(`
    query GetAssetItems($assetId: ID!) {
        assetItems(where: { assetID: $assetId }) {
            edges {
                node {
                    ...FullAssetItemFragment
                }
            }
        }
    }
`);

export const GET_PROJECT_ASSET_ITEMS = graphql(`
    query GetProjectAssetItems($pid: ID!) {
        assetItems(
            where: { hasAssetWith: { hasProjectZoneWith: { projectID: $pid } } }
        ) {
            edges {
                node {
                    ...ProjectAssetItemFragment
                }
            }
        }
    }
`);

export const GET_SINGLE_ASSET_NAME_ONLY = graphql(`
    query getAssetName($assetId: ID!) {
        assets(where: { id: $assetId }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);

export const GET_ASSET_TYPES = graphql(`
    query AssetTypes {
        assetTypes {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`);
