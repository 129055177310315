import { type ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@ics-portal/react/src/index';
import {
    BoxSwitch,
    type BoxSwitchElement,
} from '../../_atoms/BoxSwitch/box-switch';
import s from './dashboard-view-switch.module.css';

export function DashboardViewSwitch(): ReactNode {
    const navigation = useLocation();
    const navigate = useNavigate();

    const isMap = navigation.pathname.includes('map');
    const isDashboard = navigation.pathname === '/';

    const buttons: BoxSwitchElement[] = [
        {
            label: 'Dashboard',
            icon: <Icon name="dashboard" fontSize="small" />,
            onClick: () => {
                navigate('/');
            },
            active: isDashboard,
        },
        {
            label: 'Map view',
            icon: <Icon name="public" fontSize="small" />,
            onClick: () => {
                navigate('/map');
            },
            active: isMap,
        },
    ];

    return (
        <div className={s.dashboardViewSwitchWrapper}>
            <BoxSwitch buttons={buttons} />
        </div>
    );
}
