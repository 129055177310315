import type { MaterialSymbol } from 'material-symbols';
import { clsx } from 'clsx';
import { useRef } from 'react';
import type { ReactNode, HTMLAttributes } from 'react';

export type IconTypes = MaterialSymbol;

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
    /**
     * Whether the icon should be displayed in its active state.
     * It then uses the filled version of the icon.
     */
    active?: boolean;
    /**
     * The size of the icon.
     */
    fontSize?: 'small' | 'normal' | 'large';
    /**
     * The name of the icon to display.
     * @see https://fonts.google.com/icons?selected=Material+Symbols+Outlined:logout:FILL@0;wght@400;GRAD@0;opsz@24&icon.size=24&icon.color=%235f6368
     */
    name: IconTypes;
}

/**
 * Renders an Icon from the Material Symbols library.
 * It accepts any valid <span> tag properties.
 */
export function Icon({
    active = false,
    fontSize = 'normal',
    className,
    name,
    ...rest
}: IconProps): ReactNode {
    const hasBeenActiveRef = useRef(false);
    const classNames = clsx(
        'ics-icon',
        {
            'ics-icon--filled': active,
            'ics-icon--unfilled': !active && hasBeenActiveRef.current,
            'ics-icon--small': fontSize === 'small',
            'ics-icon--large': fontSize === 'large',
        },
        className,
    );

    if (!hasBeenActiveRef.current && active) {
        hasBeenActiveRef.current = true;
    }

    return (
        <span className={classNames} {...rest}>
            {name}
        </span>
    );
}
