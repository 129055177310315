import { forwardRef, type ReactNode } from 'react';
import { Switch as AriaSwitch } from 'react-aria-components';

interface SwitchProps {
    name: string;
    children?: ReactNode;
    disabled?: boolean;
    id?: string;
    value?: boolean | null;
    onChange?: (event: boolean) => void;
}

const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, _) => {
    const { children, value, disabled, ...rest } = props;
    return (
        <AriaSwitch
            isSelected={Boolean(value)}
            isDisabled={disabled}
            className="ics-switch"
            {...rest}
        >
            <div className="indicator" />
            {children}
        </AriaSwitch>
    );
});

Switch.displayName = 'Switch';

export default Switch;
