import { type MinimalUserPartialFragment, type User } from '~/gql/graphql';

export function capitalizeString(str: string): string {
    const newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
}

export function simplePluralize(input: string, count: number): string {
    return count === 0 || count > 1 ? `${input}s` : input;
}

export function fullName(
    actor: User | MinimalUserPartialFragment | undefined | null,
): string {
    return actor ? `${actor.firstName} ${actor.lastName}` : '';
}
