import {
    createContext,
    type ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { unique } from 'radash';
import { DialogState } from '@ics-portal/react';
import type { OrganizationUser, ProjectParticipant, User } from '~/gql/graphql';
import { useProjectSummary } from '../../../_utils/contexts/ProjectContext';
import { ProjectRolesHelper } from '../helpers/projectRoles';

export interface ProjectMember {
    id: string;
    firstName: string;
    lastName: string;
    role:
        | {
              id: string;
              name: string;
          }
        | undefined;
    icso: boolean;
    participant: ProjectParticipant;
}

interface DeleteDialogProps {
    displayState: DialogState;
    memberId: string;
}

interface ProjectMembersContextProps {
    selectedMembers: ProjectMember[];
    memberIdsToBeRemoved: string[];
    selectedICSOs: ProjectMember[];
    deleteDialogProps: DeleteDialogProps;
    removeMember: (id: string) => void;
    addMember: (user: User, roleId: string) => void;
    setDisplayMemberModal: (
        displayState: DialogState,
        memberId: string,
    ) => void;
    resetRemoveMemberIds: () => void;
}

const ProjectMembersContext = createContext<
    ProjectMembersContextProps | undefined
>(undefined);

function useProjectMembers(): ProjectMembersContextProps {
    const ctx = useContext(ProjectMembersContext);
    if (!ctx) {
        throw new Error(
            'useProjectMembers must be used within a ProjectMembersProvider',
        );
    }
    return { ...ctx };
}

function ProjectMembersProvider({
    children,
}: {
    children: ReactNode;
}): ReactNode {
    const [members, setMembers] = useState<ProjectMember[]>([]);
    const [memberIdsToBeRemoved, setMemberIdsToBeRemoved] = useState<string[]>(
        [],
    );
    const { project } = useProjectSummary();
    const helper = useMemo(() => new ProjectRolesHelper(project), [project]);
    const [deleteDialogProps, setDeleteDialogProps] = useState<{
        displayState: DialogState;
        memberId: string;
    }>({
        displayState: DialogState.CLOSED,
        memberId: '',
    });

    useEffect(() => {
        const participants = project.participants ?? [];
        const filteredMembers = participants
            .filter(
                (participant): participant is OrganizationUser =>
                    participant !== null &&
                    helper.isMember(participant.user.id) &&
                    participant.__typename === 'ProjectUser' &&
                    Boolean(participant.role),
            )
            .reduce<ProjectMember[]>((acc, participant) => {
                return [
                    ...acc,
                    {
                        id: participant.user.id,
                        firstName: participant.user.firstName,
                        lastName: participant.user.lastName,
                        ...helper.getProjectUserStatus(participant.user.id),
                        participant,
                    },
                ];
            }, [])
            .sort((a, b) => helper.sortByRole(a, b));
        const uniqueMembers = unique(filteredMembers, (m) => m.id);
        setMembers(uniqueMembers);
    }, [project.participants, helper]);

    const selectedICSOs: ProjectMember[] = useMemo(() => {
        const participants = project.participants ?? [];
        const filteredSelectedICSOs = participants
            .filter(
                (participant): participant is OrganizationUser =>
                    participant !== null &&
                    helper.isIcsoOnly(participant.user.id) &&
                    !members.some(
                        (member) => member.id === participant.user.id,
                    ),
            )
            .map((participant) => ({
                id: participant.user.id,
                firstName: participant.user.firstName,
                lastName: participant.user.lastName,
                ...helper.getProjectUserStatus(participant.user.id),
                participant,
            }));
        const uniqueICSOs = unique(filteredSelectedICSOs, (m) => m.id);
        return uniqueICSOs;
    }, [members, project, helper]);

    function setDisplayMemberModal(
        displayState: DialogState,
        memberId: string,
    ): void {
        setDeleteDialogProps({
            displayState,
            memberId,
        });
    }

    function resetRemoveMemberIds(): void {
        setMemberIdsToBeRemoved([]);
    }

    function removeMember(userId: string): void {
        setMembers((prev) => prev.filter((member) => member.id !== userId));
        setMemberIdsToBeRemoved([...memberIdsToBeRemoved, userId]);
        setDisplayMemberModal(DialogState.CLOSING, '');
    }

    function addMember(user: User, roleId: string): void {
        const role = ProjectRolesHelper.Roles.find(({ id }) => id === roleId);
        setMembers((prev) =>
            [
                ...prev.filter((member) => member.id !== user.id),
                {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    role,
                    icso: helper.isIcso(user.id),
                    participant: {
                        id: '',
                        role,
                        user,
                    },
                },
            ].sort((a, b) => helper.sortByRole(a, b)),
        );
    }

    return (
        <ProjectMembersContext.Provider
            value={{
                selectedMembers: members,
                memberIdsToBeRemoved,
                selectedICSOs,
                deleteDialogProps,
                removeMember,
                addMember,
                setDisplayMemberModal,
                resetRemoveMemberIds,
            }}
        >
            {children}
        </ProjectMembersContext.Provider>
    );
}

export { useProjectMembers, ProjectMembersProvider };
