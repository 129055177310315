import type { CSSProperties, ReactNode } from 'react';
import { Ratings } from './ratings';

export type RatingType = keyof typeof Ratings | undefined;

export interface RiskRatingProps {
    /**
     * The different rating levels for the RiskRating component
     */
    rating: RatingType;

    /**
     * Additional inline styles
     */
    style?: CSSProperties;

    /**
     * className string, allowing you to extend the styles of the component
     */
    className?: string;
}

const RATING_NUMBER_MAP = {
    [Ratings.LOW]: 1,
    [Ratings.MEDIUM]: 2,
    [Ratings.HIGH]: 3,
    [Ratings.VERYHIGH]: 4,
} satisfies Record<keyof typeof Ratings, number>;

export function RiskRating({
    rating,
    style,
    className,
}: RiskRatingProps): ReactNode {
    if (!rating) {
        return null;
    }
    const numberRating =
        typeof rating === 'number' ? rating : RATING_NUMBER_MAP[rating];
    /**
     * Create an array of the length of the passed in rating
     * e.g. if rating is 3, create an array of 3 elements
     */
    const array = Array.from({ length: numberRating }, (_, i) => i);
    const classNames = [
        'ics-risk-rating',
        `ics-risk-rating--rating-${numberRating.toString()}`,
        className,
    ].join(' ');
    return (
        <div className={classNames} style={style}>
            {array.map((i) => (
                <div key={i} className="ics-risk-rating__box" />
            ))}
        </div>
    );
}
