import s from './EnvironmentBanner.module.css';

const environments: Record<string, string> = {
    'ics-portal.mosaic-dev.siemens-energy.cloud': 'DEV',
    'ics-portal.mosaic-qa.siemens-energy.cloud': 'QA',
};

export default function EnvironmentBanner() {
    if (!Object.keys(environments).includes(window.location.hostname)) {
        return null;
    }

    return (
        <div className={s.banner} role="complementary">
            {environments[window.location.hostname]}
        </div>
    );
}
