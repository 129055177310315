import type { CSSProperties, HTMLProps } from 'react';
import { forwardRef } from 'react';
import {
    FloatingArrow,
    FloatingPortal,
    useMergeRefs,
    useTransitionStyles,
} from '@floating-ui/react';
import { clsx } from 'clsx';
import { useTooltipContext } from './context';

export const TooltipContent = forwardRef<
    HTMLDivElement,
    HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, className, children, ...rest }, propRef) {
    const tooltipData = useTooltipContext();
    const { styles: transitionStyles } = useTransitionStyles(
        tooltipData.context,
    );
    const ref = useMergeRefs([tooltipData.refs.setFloating, propRef]);

    if (!tooltipData.open) return null;

    const styles: CSSProperties = {
        ...transitionStyles,
        ...style,
        width: tooltipData.width,
    };

    const floatingProps = tooltipData.getFloatingProps(rest);

    const classes = clsx('ics-tooltip', className);
    if (!children) {
        return <></>;
    }

    return (
        <FloatingPortal>
            <div
                ref={ref}
                style={tooltipData.floatingStyles}
                {...floatingProps}
            >
                <div className={classes} style={{ ...styles }}>
                    {children}
                    <FloatingArrow
                        ref={tooltipData.arrowRef}
                        context={tooltipData.context}
                        tipRadius={2}
                        className="ics-tooltip__arrow"
                    />
                </div>
            </div>
        </FloatingPortal>
    );
});
