import type { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
// import './radio.css';

export interface RadioProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    /**
     * Name of the radio button
     */
    name: string;
    /**
     * Label for the radio button
     */
    label: string;
    /**
     * Value of the radio button
     */
    value: string;
    /**
     * Class name for the label
     */
    labelClassName?: string;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({ labelClassName, ...props }, ref) => {
        const svgSize = 14;
        const circleSize = svgSize - 2;
        const innerCircleSize = circleSize - 3;
        const center = svgSize / 2;

        return (
            <>
                <input
                    className="ics-radio-input-visually-hidden"
                    ref={ref}
                    id={props.value}
                    type="radio"
                    {...props}
                />
                <label
                    className={['ics-radio-compact', labelClassName].join(' ')}
                    htmlFor={props.value}
                >
                    <svg
                        aria-hidden="true"
                        className="ics-radio-compact__circle-container"
                        height={svgSize}
                        width={svgSize}
                    >
                        <circle
                            className="ics-radio-compact__circle"
                            cx={center}
                            cy={center}
                            r={circleSize / 2}
                            strokeWidth="1"
                        />
                        <circle
                            className="ics-radio-compact__inner-circle"
                            cx={center}
                            cy={center}
                            r={innerCircleSize / 2}
                            strokeWidth="1"
                        />
                    </svg>
                    {props.label}
                </label>
            </>
        );
    },
);

Radio.displayName = 'Radio';

export { Radio };
