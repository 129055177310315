import type { LabelHTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    className?: string;
    children: ReactNode | ReactNode[];
}

export default function Label({
    children,
    className,
    ...rest
}: LabelProps): ReactNode {
    const classes = clsx('ics-label', className);
    return (
        <label className={classes} {...rest}>
            {children}
        </label>
    );
}
