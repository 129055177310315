import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

/**
 * Get the environment name from the best available source
 * @returns {string} The environment
 */
function getEnvironment(): string {
    if (typeof import.meta.env.VITE_ICS_ENV === 'string') {
        /**
         * VITE_ICS_ENV is set in GitLab CI/CD variables settings with values such as (dmz, dev, qa or prod)
         */
        console.log(
            `==== Sentry Environment set to: ${
                import.meta.env.VITE_ICS_ENV
            } using VITE_ICS_ENV ====`,
        );
        return import.meta.env.VITE_ICS_ENV;
    } else if (typeof import.meta.env.MODE === 'string') {
        /**
         * Vite environment mode (development, production, test)
         */
        console.log(
            `==== Sentry Environment set to: ${
                import.meta.env.MODE
            } using env.MODE ====`,
        );
        return import.meta.env.MODE;
    }
    /**
     * Fallback to Node environment
     */
    console.log(
        `==== Sentry Environment set to: ${process.env.NODE_ENV} using node environment ====`,
    );
    return process.env.NODE_ENV || 'unknown';
}

const integrations = [
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    }),
];

const sentryConfig: Sentry.BrowserOptions = {
    dsn: 'https://ec14dad4c0354bc38ae87cb3daa10a87@crash.siemens-energy.com/93',
    release: `security-portal@${__APP_VERSION__}`,
    integrations,
    environment: getEnvironment(),
    /**
     * Set tracesSampleRate between 0 and 1 (0-100%) to capture the specified percentage of transactions for tracing.
     */
    tracesSampleRate: 1.0,
    /**
     * Set tracePropagationTargets to control for which URLs trace propagation should be enabled.
     * The current settings capture all URLs starting with forward slashes, e.g. relative URLs.
     */
    tracePropagationTargets: [/^\//],
};

/**
 * Enable Sentry tunneling in production
 * This is a workaround for the fact that ad-blockers block Sentry requests
 * We instead send the requests through our own server before sending them to Sentry
 *
 * @docs https://docs.sentry.io/platforms/javascript/troubleshooting/#using-the-tunnel-option
 */
if (import.meta.env.MODE !== 'development') {
    sentryConfig.tunnel = '/error-report-worker';
}

export default sentryConfig;
