import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { type PublicClientApplication } from '@azure/msal-browser';
import { createUploadLink } from 'apollo-upload-client';
import getToken from '../auth/msal/getToken';
import correlationIdLink from './utils/correlationId';
import errorLink from './utils/errorLink';
import backendVersionLink from './utils/backend-version-link';

export default function createApolloClient(
    msal: PublicClientApplication,
    apiUri: string,
) {
    const httpLink = createUploadLink({
        uri: apiUri,
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = await getToken(msal);
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : null,
            },
        };
    });

    return new ApolloClient({
        /**
         * Combine several links into a single link, each one works like middleware.
         * The last link is the terminating link, and should always be the http link.
         * @docs https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link
         */
        link: from([
            correlationIdLink,
            backendVersionLink,
            errorLink,
            authLink,
            httpLink,
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                ProjectComponentNotification: {
                    keyFields: ['assetItemID', 'notificationID', 'componentID'],
                },
            },
        }),
        defaultOptions: {
            query: {
                // @see https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
                fetchPolicy: 'cache-first',
            },
        },
    });
}
