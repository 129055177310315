import { useLazyQuery } from '@apollo/client';
import { Button } from '@ics-portal/react';
import { EXPORT_PROJECT_ASSETS_LIST } from '~/api/queries/exports';
import type { Scalars } from '~/gql/graphql';
import { saveExcelExportAsFile } from '~/utils/data-utils';
import { DownloadIcon } from '../icons';

interface Props {
    pid: Scalars['ID'];
    label?: string;
}

export default function DownloadProjectAssetsButton({
    pid,
    label = 'Download assets',
}: Props): JSX.Element {
    const [runExportProjectAssetsListQuery] = useLazyQuery(
        EXPORT_PROJECT_ASSETS_LIST,
        {
            fetchPolicy: 'no-cache',
            variables: {
                pid,
            },
        },
    );

    function download() {
        runExportProjectAssetsListQuery().then(({ data }) => {
            if (data?.exportAssetsList) {
                saveExcelExportAsFile(data.exportAssetsList);
            }
        });
    }

    return (
        <Button
            icon={<DownloadIcon />}
            label={label}
            onClick={download}
            variant="tertiary"
        />
    );
}
