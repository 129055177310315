import { MapContainer, TileLayer } from 'react-leaflet';
import { type Map as LeafletMap } from 'leaflet';
import { type ForwardedRef, forwardRef, type ReactNode } from 'react';
import { ZoomController } from './zoom-controller';
import { type MapProps } from './types';
import { MapControlContainer } from './map-control-container';
import s from './map.module.css';
import 'leaflet/dist/leaflet.css';

function MapComponent(
    { controls, children }: MapProps,
    ref: ForwardedRef<LeafletMap>,
): ReactNode {
    const center = { lat: 48.505, lng: 20.09 };
    const url = import.meta.env.VITE_MAP_TILE_URL as string;
    return (
        <MapContainer
            ref={ref}
            className={s.map}
            center={center}
            attributionControl={false}
            zoomControl={false}
            zoomSnap={0.5}
            zoom={5}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url={url}
            />

            {controls?.map((control, index) => {
                if (control.control === 'zoom') {
                    return (
                        <MapControlContainer
                            key={index}
                            control={<ZoomController />}
                            position={control.position}
                        />
                    );
                }
                return <MapControlContainer key={index} {...control} />;
            })}
            {children}
        </MapContainer>
    );
}

const Map = forwardRef<LeafletMap, MapProps>(MapComponent);

export { Map };
